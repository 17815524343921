<script lang="ts" setup>
import favoriteAnimation from '~/assets/animations/favorite-animation.json';
import type LayoutLottie from '~/components/layout/Lottie.vue';
import { useApplyToJob } from '../../composables/useApplyToJob';
import { useWageFormatting } from '../../composables/useWageFormatting';

// #region Globals
const userInfoStore = useUserInfoStore();
const { userInfo } = storeToRefs(userInfoStore);
const { duoTone } = useBranding();
const { t } = useI18n();
// #endregion

// #region Props
const emits = defineEmits<{
   (e: 'toggleLike'): void;
   (e: 'applied'): void;
   (e: 'disliked'): void;
}>();
const props = defineProps({
   job: { type: Object as PropType<Position>, required: true },
   hasMoreInfoButton: { type: Boolean, default: true },
   hasApplied: { type: Boolean, default: false },
   shortActions: { type: Boolean, default: false },
   swipeEnabled: { type: Boolean, default: true },
});
// #endregion

// #region Apply Now
const { applyToJob } = useApplyToJob();
function showApplyModal() {
   applyToJob(props.job);
}
// #endregion

// #region Mappings
const company = computed(() => props.job.customer?.company);

const timetables = computed(() => props.job.profile?.timetables || []);
// #endregion

// #region Job Types
const isFlexi = computed(() => props.job.profile?.job_types?.find((pjt) => pjt.job_type?.key === JobTypeKey.FlexiJob));
const isStudent = computed(() => props.job.profile?.job_types?.find((pjt) => pjt.job_type?.key === JobTypeKey.StudentJob));
// #endregion

// #region favorite
const isLiked = ref<boolean>(props.job.is_liked);
const likeRef = ref<InstanceType<typeof LayoutLottie>>();

const { execute: like } = useAuthFetch(POSITION_ENDPOINTS.LIKE(props.job.sync_id), { immediate: false, method: 'PUT' });
const { execute: unlike } = useAuthFetch(POSITION_ENDPOINTS.UNLIKE(props.job.sync_id), { immediate: false, method: 'PUT' });

function animationLoaded() {
   likeRef.value?.goToAndStop(isLiked.value ? 10 : 0);
}

function toggleLike() {
   if (!isLiked.value) {
      likeRef.value?.play();
      like().then(() => (isLiked.value = true));
   } else {
      likeRef.value?.stop();
      unlike().then(() => (isLiked.value = false));
   }
   emits('toggleLike');
}

function watchLike(newValue: boolean) {
   if (newValue !== isLiked.value) {
      toggleLike();
   }
}

watch(() => props.job.is_liked, watchLike);
// #endregion

// #region Wage
const { wage } = useWageFormatting(props.job);
// #endregion

// #region Menu
const showMenu = ref(false);
const { execute: dislike } = useAuthFetch(POSITION_ENDPOINTS.DISLIKE(props.job.sync_id), { immediate: false, method: 'PUT' });
const { openShare } = useLinks();

async function dislikeJob() {
   await dislike();
   emits('disliked');

   showMenu.value = false;
}

function shareJob() {
   openShare({
      title: t('jobs.share-title', { first_name: userInfo.value?.first_name }),
      url: `${window.location.origin}/jobs/${props.job.sync_id}`,
      text: props.job.title,
   });
}
// #endregion
</script>

<template>
   <div class="jobs-card">
      <NuxtLink :prefetch="true" class="jobs-card__card" :to="`/jobs/${job.sync_id}`">
         <header>
            <LayoutCustomerLogos
               class="jobs-card__customer-logo"
               :logos="[{ logo: company.public_logo_url ?? '', name: company?.name }]"
               :size="32"
            />
            <h2 class="jobs-card__job-function">{{ job.title }}</h2>
            <p class="jobs-card__customer-name">{{ job.customer.name }}</p>
         </header>

         <main>
            <div class="jobs-card__left">
               <div v-if="job.address" class="jobs-card__property">
                  <i class="fas fa-location-dot"></i>
                  <span>{{ job?.address?.city?.name }}</span>
               </div>
               <div v-if="timetables.length > 0" class="jobs-card__property">
                  <i class="fas fa-clock"></i>
                  <span>{{ timetables.map((tt) => tt.timetable?.name).join(' - ') }}</span>
               </div>
               <div v-if="wage" class="jobs-card__property">
                  <i class="fas fa-euro-sign"></i>
                  <span> {{ wage }}</span>
               </div>
            </div>

            <div class="jobs-card__right">
               <StatusTag
                  v-if="isStudent"
                  :color="'var(--nxt-dark)'"
                  :background-color="'var(--nxt-orange)'"
                  class="jobs-card__tag -student"
               >
                  {{ $t('jobs.student') }}
               </StatusTag>

               <StatusTag v-if="isFlexi" :color="'var(--nxt-dark)'" :background-color="'var(--nxt-orange)'" class="jobs-card__tag -flexi">
                  {{ $t('jobs.flexi') }}
               </StatusTag>

               <div class="jobs-card__actions">
                  <span v-if="!!hasApplied">
                     <span> {{ $t('jobs.already-applied') }}</span>
                  </span>

                  <ButtonMain
                     v-else
                     :background-color="duoTone ? 'var(--nxt-secondary)' : 'var(--nxt-main)'"
                     :icon-name="'check'"
                     @on-click="showApplyModal"
                  >
                     <span>{{ $t('jobs.apply-now') }}</span>
                  </ButtonMain>
               </div>
            </div>
         </main>

         <div class="jobs-card__quick-actions">
            <LayoutLottie
               ref="likeRef"
               :class="'jobs-card__like'"
               :animation-data="favoriteAnimation"
               :auto-play="false"
               :loop="false"
               :speed="1.5"
               @click.stop.prevent="toggleLike"
               @on-animation-loaded="animationLoaded"
            />

            <ButtonIcon :class="'jobs-card__menu'" :icon-name="'ellipsis-vertical'" @on-click="showMenu = true" />
         </div>

         <!-- Apply -->
      </NuxtLink>

      <JobsMenuModal v-if="showMenu" @dislike="dislikeJob" @share="shareJob" @close="showMenu = false" />
   </div>
</template>

<style lang="scss">
.jobs-card {
   display: flex;
}

.jobs-card__card {
   position: relative;
   height: 100%;
   align-items: flex-start;
   background-color: var(--nxt-card-background);
   border-radius: var(--nxt-radius);
   color: var(--nxt-dark);
   cursor: pointer;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   gap: var(--nxt-gutter);
   padding: var(--nxt-gutter-small);
   position: relative;
   text-decoration: none !important;
   width: 100%;
}

.jobs-card__content {
   z-index: 1;
}

.jobs-card header {
   display: grid;
   grid-template-columns: auto 1fr;
   grid-template-rows: auto auto;
   grid-template-areas: 'logo job' 'logo customer';
   gap: 0 var(--nxt-gutter-small);
   margin-right: toRem(80);
}

.jobs-card__customer-logo {
   grid-area: logo;
   justify-self: center;
   align-self: center;
}

.jobs-card__customer-name {
   grid-area: customer;
   justify-self: start;
   align-self: center;
   margin: 0;
   font-size: var(--nxt-font-small);

   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
   max-width: 100%;
}

.jobs-card__job-function {
   grid-area: job;
   justify-self: start;
   align-self: center;
   margin: 0;
   font-size: 1.2rem;

   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
   max-width: 100%;
}

.jobs-card main {
   width: 100%;
   max-width: 100%;
   display: flex;
   justify-content: space-between;
}

.jobs-card__left {
   display: flex;
   flex-direction: column;
   gap: var(--nxt-gutter-small);
   font-weight: 300;
   max-width: 100%;
   overflow: hidden;
}

.jobs-card__right {
   display: flex;
   flex-direction: column;
   align-items: flex-end;
   gap: var(--nxt-gutter-small);
}

.jobs-card__property {
   display: flex;
   align-items: center;
   gap: var(--nxt-gutter-small);
   font-size: var(--nxt-font-base);

   span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
   }
}

.jobs-card__actions {
   display: flex;
   align-items: flex-end;
   height: 100%;
   margin: toRem(5);

   > * {
      white-space: nowrap;
   }
}

.jobs-card__tag {
   font-size: var(--nxt-font-small);
}

.jobs-card__quick-actions {
   display: flex;
   justify-content: space-between;
   position: absolute;
   top: 0;
   right: 0;
}

.jobs-card__like {
   grid-area: heart;
   height: toRem(45);
   width: toRem(45);
}

.jobs-card__menu {
   grid-area: menu;
   height: toRem(45);
   width: toRem(45);
}
</style>
